<template>
  <b-overlay rounded="sm" :show="loading">
    <b-card class="m-5">
      <div>
        <div class="text-center">
          <div>
            <h1>Step 1 / 3</h1>
          </div>
          
          <div class="my-2">
            <p>Please provide the information below</p>
          </div>
        </div>

        <b-alert variant="danger" show>
          <div v-if="errorMessage" class="alert-body font-small-2">
            <b-card-text>
              <feather-icon icon="AlertCircleIcon" />
              <small>{{ errorMessage }}</small>
            </b-card-text>
          </div>
        </b-alert>

        <div>
          <validation-observer ref="residentialForm">
            <b-form @submit.prevent="saveOnboardingData">
              <b-row>
                <b-col cols="12" md="6">
                  <h3 class="mb-2">Personal Information</h3>
                  <b-form-group label="First Name" label-for="first_name">
                    <template #label>
                      <span>First Name<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="first_name" rules="required">
                      <div class="custom-input">
                        <span class="leading">
                          <feather-icon icon="UserIcon" size="20" />
                        </span>
                        <input id="first_name" v-model="first_name" placeholder="Enter your First Name Here">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Last Name" label-for="last_name">
                    <template #label>
                      <span>Last Name<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="last_name" rules="required">
                      <div class="custom-input">
                        <span class="leading">
                          <feather-icon icon="UserIcon" size="20" />
                        </span>
                        <input id="last_name" v-model="last_name" placeholder="Enter your Last Name Here">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Gender" label-for="gender" class="mt-2">
                    <template #label>
                      <span>Gender<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="gender" rules="required">
                      <div class="custom-input">
                        <span class="leading">
                          <feather-icon icon="HelpCircleIcon" size="20" />
                        </span>
                        <b-form-select id="gender" v-model="gender" :options="genderOptions" style="height: 3.3rem" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Date of Birth" label-for="date_of_birth">
                    <template #label>
                      <span>Date of Birth<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="date_of_birth" rules="required">
                      <div class="custom-input">
                        <span class="leading">
                          <feather-icon icon="CalendarIcon" size="20" />
                        </span>
                        <input id="date_of_birth" v-model="date_of_birth" placeholder="Enter your Date of birth Here" type="date">
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Country" label-for="country">
                    <template #label>
                      <span>Country<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="country" rules="required">
                      <v-select
                        id="country"
                        v-model="country"
                        :loading="loading"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Region/State/Province" label-for="region">
                    <template #label>
                      <span>Region/State/Province<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="region" rules="required">
                      <v-select
                        id="region"
                        v-model="region"
                        :loading="loading"             
                        :clearable="false"
                        :options="regionOptions"
                        :reduce="val => val.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="City" label-for="city">
                    <template #label>
                      <span>City<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="city" rules="required">
                      <v-select
                        id="city"
                        v-model="city"
                        :loading="loading"             
                        :clearable="false"
                        :options="cityOptions"
                        :reduce="val => val.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Town/Area" label-for="town">
                    <validation-provider #default="{ errors }" name="town" rules="">
                      <v-select
                        id="town"
                        v-model="town"
                        :loading="loading"             
                        :clearable="false"
                        :options="townOptions"
                        :reduce="val => val.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                  <h3 class="mb-2">Fellowship Information</h3>
                  <b-form-group label="Region" label-for="region">
                    <template #label>
                      <span>Region<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="region" rules="required">
                      <v-select
                        id="region"
                        v-model="church_region"
                        :loading="loading"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="churchRegionOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Area" label-for="area">
                    <template #label>
                      <span>Area<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="area" rules="required">
                      <v-select
                        id="area"
                        v-model="church_area"
                        :loading="loading"             
                        :clearable="false"
                        :options="churchAreaOptions"
                        :reduce="val => val.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="District" label-for="region">
                    <template #label>
                      <span>District<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="district" rules="required">
                      <v-select
                        id="district"
                        v-model="church_district"
                        :loading="loading"             
                        :clearable="false"
                        :options="churchDistrictOptions"
                        :reduce="val => val.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Assembly" label-for="assembly">
                    <template #label>
                      <span>Assembly<span style="color: tomato">*</span></span>
                    </template>

                    <validation-provider #default="{ errors }" name="assembly" rules="required">
                      <v-select
                        id="assembly"
                        v-model="church_assembly"
                        :loading="loading"             
                        :clearable="false"
                        :options="churchAssemblyOptions"
                        :reduce="val => val.value"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <div class="d-flex my-1 mt-2 justify-content-between align-items-center">
                    <div>
                      <b-button type="submit" variant="primary" block class="py-1">
                        Proceed
                        <feather-icon icon="ChevronRightIcon" />
                      </b-button>
                    </div>

                    <b-card-text class="text-right mt-0 text-danger cursor-pointer" @click="logout">
                      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                      <span>Logout</span>
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { required } from '@validations';
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import vSelect from 'vue-select'
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCol,
  BImg,
  BRow,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BCard,
  BCardText,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormDatepicker
} from 'bootstrap-vue'

export default {
  components: {
    vSelect,

    AuthWrapper,
    BOverlay,
    BAlert,
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BFormSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      hasErrorMessage: false,

      first_name: '',
      last_name: '',
      location: '',
      gender: '',
      date_of_birth: '',
      genderOptions: [
        {
          value: "female",
          text: "Female"
        },
        {
          value: "male",
          text: "Male"
        }
      ],

      country: 2300660,
      region: '',
      city: '',
      town: '',

      countryOptions: [],
      regionOptions: [],
      cityOptions: [],
      townOptions: [],

      church_region: '',
      church_district: '',
      church_area: '',
      church_assembly: '',

      churchRegionOptions: [],
      churchAreaOptions: [],
      churchDistrictOptions: [],
      churchAssemblyOptions: [],

      // validation
      required,
    }
  },
  watch: {
    country: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onRegionsFetch);
        }
      },
      immediate: true
    },
    region: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onCitiesFetch);
        }
      },
      immediate: true
    },
    city: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onTownsFetch);
        }
      },
      immediate: true
    },
    church_region: {
      handler(changed){
        if (changed){
          this.fetchChurchAreas();
        }
      },
      immediate: true
    },
    church_area: {
      handler(changed){
        if (changed){
          this.fetchChurchDistrict();
        }
      },
      immediate: true
    },
    church_district: {
      handler(changed){
        if (changed){
          this.fetchChurchAssemblies();
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchCountries();
    this.fetchChurchRegions();
  },
  mounted() {
    this.first_name = this.getValueFromSource(this.currentUser, 'first_name');
    this.last_name = this.getValueFromSource(this.currentUser, 'last_name');
    this.gender = this.getValueFromSource(this.currentUser, 'gender');
    this.date_of_birth = this.getValueFromSource(this.currentUser, 'date_of_birth');

    this.country = this.getValueFromSource(this.currentUser, 'country', 2300660);
    this.region = this.getValueFromSource(this.currentUser, 'region');
    this.city = this.getValueFromSource(this.currentUser, 'city');
    this.town = this.getValueFromSource(this.currentUser, 'town');

    this.church_region = this.getValueFromSource(this.currentUser, 'church_region');
    this.church_district = this.getValueFromSource(this.currentUser, 'church_district');
    this.church_area = this.getValueFromSource(this.currentUser, 'church_area');
    this.church_assembly = this.getValueFromSource(this.currentUser, 'church_assembly');
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;
        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.geo_name_id
        }));

        this.region = '';
        this.city = '';
        this.town = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchGeoData(geo_id, cb) {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchGeoData({ geo_id });
        const { data } = request.data;
        cb(data)
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchRegions();
        const { data } = request.data;
        this.churchRegionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_region = '';
        this.church_area = '';
        this.church_district = '';
        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAreas(this.church_region);
        const { data } = request.data;
        this.churchAreaOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_area = '';
        this.church_district = '';
        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchDistricts(this.church_region, this.church_area);
        const { data } = request.data;
        this.churchDistrictOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_district = '';
        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAssemblies(this.church_region, this.church_area, this.church_district);
        const { data } = request.data;
        this.churchAssemblyOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.church_assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async saveOnboardingData() {
      try {
        this.loading = true;
        const success = await this.$refs.residentialForm.validate();
        if (!success) {
          return;
        }

        const {
          first_name,
          last_name,
          gender,
          country,
          region,
          city,
          town,
          date_of_birth,
          church_region,
          church_district,
          church_area,
          church_assembly

        } = this;

        const formData = new FormData()
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("gender", gender);
        formData.append("country", country);
        formData.append("region", region);
        formData.append("city", city);
        formData.append("town", town);
        formData.append("date_of_birth", date_of_birth);
        formData.append("church_region", church_region);
        formData.append("church_district", church_district);
        formData.append("church_area", church_area);
        formData.append("church_assembly", church_assembly);

        await this.useJwt().authService.saveClientOnboardingData(formData);

        this.$router.replace({
            name: "client-home"
          })
          .catch(() => {});

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Welcome to Pentecost Job Search App. Kindly complete setting up your CV from the Manage CV page.`
          },
        }, {
          timeout: 60000
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRegionsFetch(data) {
      this.cityOptions = [];
      this.townOptions = [];
      this.region = '';
      this.city = '';
      this.town = '';

      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onCitiesFetch(data) {
      this.townOptions = [];
      this.city = '';
      this.town = '';

      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onTownsFetch(data) {
      this.town = '';
      this.townOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.custom-input {
    position: relative;
    display: block;
    color:  #80A0AF !important;

    .leading{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      padding-left: 12px;
      padding-right: 0.5rem;
      display: flex;
      align-items: center;
    }

    .trailing {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding-right: 12px;
      padding-left: 0.5rem;
      display: flex;
      align-items: center;
    }

    .otp {
      letter-spacing: 2rem;
      font-weight: bold;
      text-align: center !important;
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
      padding-left: 3.5rem !important;
    }


    input, textarea, select {
      border: 1px solid $neutral;
      border-radius: 5px;
      width: 100%;
      top: 50%;
      bottom: 50%;
      min-width: 50px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2.75rem;
      color:  #80A0AF !important;

      &:focus {
        outline: none;
        border: 1px solid $main-color;
      }

      &::placeholder {
        color: $neutral;
        opacity: 0.3;
      }
      &:invalid {
        border-color: red;
      }
      &:required {
        border-color: red;
      }
      &:-ms-input-placeholder {
        color: $neutral;
      }

    }
  }
</style>
